import Router from 'next/router'
import React from 'react'
import { Flex, Box, Chip, Text } from '@traefiklabs/faency'

import { DateTime } from 'luxon'
import PopoverMenu from '../PopoverMenu'
import EditableHeading from '../EditableHeading'

import { TokenType } from './InstanceCard'
import { ResourceStatus } from '../ResourceStatus'

type InstanceCardHeaderProps = {
  token: TokenType
  mutate: () => void
  updatedAt?: string
  createdAt?: string
  count?: number
  isHovered?: boolean
  onRevokeToken?: () => void
  onRename?: (newName: string) => void
  isWaiting?: boolean
  withMetrics?: boolean
}

const InstanceCardHeader: React.FC<InstanceCardHeaderProps> = ({
  token,
  updatedAt,
  createdAt,
  count = 0,
  isHovered = false,
  onRevokeToken = () => null,
  onRename = () => null,
  isWaiting = false,
  withMetrics = false,
}) => {
  const menuItems = [
    {
      id: 'metrics',
      icon: 'activity',
      title: 'Show Metrics',
      onPress: () => Router.push(`/metrics?instanceId=${token.id}`),
    },
    {
      id: 'revoke-token',
      icon: 'trash-outline',
      title: 'Delete Instance',
      onPress: () => onRevokeToken(),
      variant: 'negative',
    },
  ]

  const diffFromNowVerbose = (date: string): string => {
    const now = DateTime.local()
    const luxonDt = DateTime.fromISO(date)
    return luxonDt.toRelative({ base: now, round: true, locale: 'en', style: 'narrow' })
  }

  const updatedAtDate = DateTime.fromISO(updatedAt)
  const diffFromNow = updatedAtDate.diffNow('minutes')?.values?.minutes

  if (withMetrics) {
    return (
      <Flex p={3} sx={{ flex: 1, flexDirection: 'column', alignSelf: 'stretch', position: 'relative' }}>
        <Flex mb={3} sx={{ alignItems: 'center' }}>
          <Box mr={2}>
            <ResourceStatus status={diffFromNow < -5 ? 'warning' : 'enabled'} />
          </Box>
          <Box mr={2}>
            <EditableHeading onValueChange={onRename} isLoading={isWaiting} isHover={isHovered}>
              {token.name}
            </EditableHeading>
          </Box>
        </Flex>

        <Flex mb={2} sx={{ alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
          {!!createdAt && (
            <Text size={2} sx={{ fontWeight: 500, color: 'grays.5' }}>
              Created {diffFromNowVerbose(createdAt)}
            </Text>
          )}
        </Flex>

        <Box>
          <Chip variant="blue">{count > 0 ? `${count || 0} ${count > 1 ? 'proxies' : 'proxy'}` : 'no proxies'}</Chip>
        </Box>

        <Box p={1} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <PopoverMenu menuItems={menuItems} data-testid="instance-menu-btn" />
        </Box>
      </Flex>
    )
  }

  return (
    <Flex p={3} sx={{ flex: 1, alignSelf: 'stretch', alignItems: 'center', flexWrap: 'wrap' }}>
      <Flex mr={2} sx={{ alignItems: 'center' }}>
        <ResourceStatus status={diffFromNow < -5 ? 'warning' : 'enabled'} />
      </Flex>
      <Box mr={9} sx={{ maxWidth: '50%' }}>
        <EditableHeading onValueChange={onRename} isLoading={isWaiting} isHover={isHovered}>
          {token.name}
        </EditableHeading>
      </Box>

      <Flex mr={4} sx={{ alignItems: 'center', flex: 1 }}>
        {!!createdAt && (
          <Text size={1} sx={{ fontWeight: 500, color: 'grays.5' }}>
            Created {diffFromNowVerbose(createdAt)}
          </Text>
        )}
      </Flex>

      <Chip mr={4} variant="blue">
        {count > 0 ? `${count || 0} ${count > 1 ? 'proxies' : 'proxy'}` : 'no proxies'}
      </Chip>

      <PopoverMenu menuItems={menuItems} data-testid="instance-menu-btn" />
    </Flex>
  )
}
export default InstanceCardHeader
