import React, { useState } from 'react'
import { Box, Button, Text, Tooltip } from '@traefiklabs/faency'
import Icon from 'react-eva-icons'
import useParentIframe from '../hooks/use-parent-iframe'

type CopyableTokenProps = {
  value: string
  color?: string
}

const CopyableToken: React.FC<CopyableTokenProps> = ({ value, color = 'hsl(129,93%,30%)' }) => {
  const initialCopyState = 'copy token'
  const [copyState, setCopyState] = useState(initialCopyState)
  const [sendMessage] = useParentIframe(true)
  return (
    <Box data-testid="copyable-token">
      <Tooltip label={copyState} preferredAlignment="right" preferredPosition="top">
        <Button
          type="button"
          px={1}
          sx={{
            height: 24,
            display: 'inline-flex',
            alignItems: 'center',
            bg: 'hsl(143,34%,92%)',
            borderRadius: 8,
            userSelect: 'text',
          }}
          onClick={(e: React.MouseEvent): void => {
            e.stopPropagation()
            if (navigator?.permissions) {
              navigator.clipboard.writeText(value)
              sendMessage({ type: 'copy-to-clipboard', value })
              setCopyState('copied !')
            } else {
              setCopyState('copy disabled')
            }
          }}
          onMouseLeave={() => setTimeout(() => setCopyState(initialCopyState), 100)}
        >
          <Text pr={1} sx={{ color, fontSize: 2, fontWeight: 400 }}>
            <code>{value}</code>
          </Text>{' '}
          <Text p={0} sx={{ color: '#0091ff', display: 'inline-flex', alignItems: 'center' }}>
            <Icon name="copy-outline" fill="currentColor" />
          </Text>
        </Button>
      </Tooltip>
    </Box>
  )
}

export default CopyableToken
