import { Box } from '@traefiklabs/faency'
import styled, { createGlobalStyle } from 'styled-components'

const BodyGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const ModalOverlay = styled(Box).attrs({
  children: <BodyGlobalStyle />,
})`
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(123, 123, 123, 0.3);
`

export default ModalOverlay
