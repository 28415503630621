import { Box } from '@traefiklabs/faency'
import { motion, AnimatePresence } from 'framer-motion'
import theme from '../theme'

type ExpandableBoxProps = {
  children: React.ReactNode
  isExpanded?: boolean
  dashedBorder?: boolean
}

const CustomBox = motion.custom(Box)

const ExpandableBox: React.FC<ExpandableBoxProps> = ({ children, isExpanded = false, dashedBorder = false }) => {
  return (
    <AnimatePresence>
      {isExpanded && (
        <CustomBox
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.3 }}
          sx={{
            bg: 'grays.0',
            alignSelf: 'stretch',
            borderTop: `1px solid ${theme.colors.gray}`,
            ...(dashedBorder ? { borderTopStyle: 'dashed', borderTopWidth: 2 } : {}),
            overflow: 'hidden',
            borderBottomLeftRadius: theme.radii[2],
            borderBottomRightRadius: theme.radii[2],
            boxShadow: 'inset 0 6px 12px 0 rgba(31, 3, 35, 0.03)',
          }}
        >
          <CustomBox
            key="content2"
            variants={{ collapsed: { scale: 0.3 }, open: { scale: 1 } }}
            transition={{ duration: 0.3 }}
            sx={{ transformOrigin: 'top center' }}
          >
            {children}
          </CustomBox>
        </CustomBox>
      )}
    </AnimatePresence>
  )
}

export default ExpandableBox
