import { useState, useMemo, MutableRefObject } from 'react'
import { useRouter } from 'next/router'
import ErrorPage from 'next/error'
import qs from 'query-string'
import { useSWRInfinite } from 'swr'
import { Box, FormMessage, Flex, Heading } from '@traefiklabs/faency'
import { AnimatePresence } from 'framer-motion'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import InstanceList, { Skeleton } from '../../components/instances/InstanceList'
import { TokenType } from '../../components/instances/InstanceCard'
import InstanceToRegister from '../../components/instances/InstanceToRegister'
import WhyRegisterInstanceCard from '../../components/instances/WhyRegisterInstanceCard'
import CurrentInstance from '../../components/instances/CurrentInstance'
import AlertSwitch from '../../components/alerting/AlertSwitch'
import { useCurrentInstance } from '../../contexts/current-instance'
import useAuth from '../../hooks/use-auth'
import { getStatusCodeFromContext } from '../../utils/permissions'
import SideNavbarLayout from '../../layouts/SideNavbarLayout'

type PageType = {
  nextPage?: number
  data: TokenType[]
}

type InstancesPageProps = React.FC<{
  error?: number
  register?: boolean
}> & {
  getLayout?: (component: JSX.Element) => JSX.Element
}

const fetchUrl = '/api/services/tokens/'

const InstancesPage: InstancesPageProps = ({ error: errorProp }) => {
  const { user, loading: loadingUser } = useAuth()
  const { state: currentInstance } = useCurrentInstance()
  const { query } = useRouter()

  const { data, error, size, setSize, mutate } = useSWRInfinite((_, previousPageData) => {
    const nextPage = previousPageData?.nextPage
    const basePathFromParams = fetchUrl.slice(0).split('?')
    const basePath = basePathFromParams.length ? basePathFromParams[0] : fetchUrl
    const newParams = nextPage ? { start: nextPage } : {}
    return user ? `${basePath}?${qs.stringify(newParams)}` : null
  })

  // All instances having a name minus current instance
  const instances = useMemo(() => [].concat(...(data || []).map((page: PageType) => page.data)), [data])

  const userInstances = useMemo(() => instances.filter((i) => i.id !== currentInstance?.token?.id), [instances])

  const [isRegistering, setIsRegistering] = useState<boolean>(false)

  const isLoading = !data && !error
  const hitEnd = !(data && data[data.length - 1].nextPage)

  const infiniteRef: MutableRefObject<undefined> = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !hitEnd,
    onLoadMore: () => setSize(size + 1),
  })

  const isInTraefikContext = !!currentInstance
  const currentInstanceIsRegistered = currentInstance?.id != null

  const requestPending = (!!user && isLoading) || loadingUser

  if (errorProp) {
    return <ErrorPage statusCode={errorProp} />
  }

  return (
    <>
      {!isLoading && isInTraefikContext && instances.length > 0 && (
        <Box mb={3}>
          <Heading as="h2" size={3} mb={3}>
            Current Instance
          </Heading>
          {!currentInstanceIsRegistered && (
            <InstanceToRegister
              step={query?.action === 'register' ? 2 : 1}
              currentReplica={currentInstance}
              onNewRegistration={() => {
                return mutate().then(() => {
                  setIsRegistering(false)
                })
              }}
            />
          )}
          {currentInstanceIsRegistered && <CurrentInstance onRevokeToken={mutate} />}
        </Box>
      )}

      {error ? (
        <Box py={2}>
          <FormMessage
            message="Error fetching Instances. Please try again later or contact the support team."
            hasIcon
          />
        </Box>
      ) : (
        <>
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }} mb={3}>
            {((instances?.length > 0 && !requestPending) || requestPending) && (
              <Heading as="h2" size={3}>
                My Instances
              </Heading>
            )}

            {!!user && instances?.length > 0 && <AlertSwitch />}
          </Flex>

          {requestPending && [...Array(3)].map((_, i) => <Skeleton key={i} />)}
          {(!instances.length && user && !isLoading) || (!loadingUser && !user) ? (
            <Box>
              <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                <AnimatePresence initial={false}>{!isRegistering && <WhyRegisterInstanceCard />}</AnimatePresence>
                <Box mb={4} sx={{ alignSelf: 'stretch' }}>
                  <InstanceToRegister
                    step={query?.action === 'register' ? 2 : 1}
                    {...(currentInstance ? { currentReplica: currentInstance } : {})}
                    onStepChange={(step) => {
                      if (!isRegistering && step === 2) {
                        setIsRegistering(true)
                      }

                      if (isRegistering && step === 1) {
                        setIsRegistering(false)
                      }
                    }}
                    onNewRegistration={async () => {
                      return mutate().then(() => {
                        setIsRegistering(false)
                      })
                    }}
                  />
                </Box>
              </Flex>
            </Box>
          ) : (
            <Flex sx={{ flexDirection: 'column' }}>
              {!isLoading && instances?.length > 0 && (!isInTraefikContext || currentInstanceIsRegistered) && (
                <Box mb={4} sx={{ alignSelf: 'stretch' }}>
                  <InstanceToRegister
                    step={query?.action === 'register' ? 2 : 1}
                    onNewRegistration={() => {
                      return mutate().then(() => {
                        setIsRegistering(false)
                      })
                    }}
                  />
                </Box>
              )}
              <Box ref={infiniteRef}>
                <InstanceList instances={userInstances} mutate={mutate} />
              </Box>
            </Flex>
          )}
        </>
      )}
    </>
  )
}

export const getServerSideProps = async (ctx) => {
  const statusCode = await getStatusCodeFromContext(ctx)

  if (statusCode !== 200) {
    return {
      props: {
        error: statusCode,
      },
    }
  }

  return {
    props: {}, // will be passed to the page component as props
  }
}

InstancesPage.getLayout = (page) => <SideNavbarLayout title={'Instances'}>{page}</SideNavbarLayout>

export default InstancesPage
