import { useState, useMemo } from 'react'
import { useSWRInfinite } from 'swr'
import qs from 'query-string'
import { Flex, Box, Button, Card, FormMessage, Text, SkeletonText } from '@traefiklabs/faency'
import { useCurrentInstance } from '../../contexts/current-instance'
import useLazyFetch from '../../hooks/use-lazy-fetch'
import ReplicaRow, { ReplicaType, Skeleton as ReplicaRowSkeleton } from '../replicas/ReplicaRow'
import ExpandableBox from '../ExpandableBox'
import InstanceCardHeader from './InstanceCardHeader'
import ConfirmModal from '../ConfirmModal'
import InstanceCardMetrics from './InstanceCardMetrics'
import Link from 'components/Link'

export type TokenType = {
  createdAt: string
  disabled: boolean
  encryptedValue: string
  id: string
  userId: string
  name?: string
}

type InstanceCardProps = {
  token: TokenType
  mutate: (props?: unknown) => void
  withMetrics?: boolean
  onRevokeToken?: () => void
}

export const Skeleton: React.FC = () => (
  <Card variant="shadow">
    <Flex sx={{ flex: 1, alignSelf: 'stretch', alignItems: 'center' }}>
      <Flex sx={{ flex: 1, height: 32, alignItems: 'center' }}>
        <SkeletonText sx={{ height: '20px !important', width: 300 }} />
      </Flex>

      <Flex mr={4} sx={{ height: 32, alignItems: 'center' }}>
        <SkeletonText sx={{ height: '20px !important', width: 100 }} />
      </Flex>
      <Flex sx={{ height: 32, alignItems: 'center' }}>
        <SkeletonText sx={{ height: '20px !important', width: 20 }} />
      </Flex>
    </Flex>
  </Card>
)

type PageType = {
  data: ReplicaType[]
  nextPage?: number
}

const InstanceCard: React.FC<InstanceCardProps> = ({ token, mutate, withMetrics = false }) => {
  const [revokeMode, setRevokeMode] = useState(false)
  const [isCardHover, setCardIsHover] = useState(false)
  const [isExpanded, setExpanded] = useState(false)

  const fetchUrl = `/api/services/instances/`

  const { data, error, size, setSize } = useSWRInfinite((_, previousPageData) => {
    const nextPage = previousPageData?.nextPage
    const basePathFromParams = fetchUrl.slice(0).split('?')
    const basePath = basePathFromParams.length ? basePathFromParams[0] : fetchUrl
    const newParams = { token: token.id, ...(nextPage ? { start: nextPage } : {}) }
    return `${basePath}?${qs.stringify(newParams)}`
  })

  const hitEnd = !(data && data[data.length - 1].nextPage)

  const replicas = useMemo(() => [].concat(...(data || []).map((page: PageType) => page.data)), [data])

  const hasSkeleton = !data && !error
  const { state: currentInstance, dispatch } = useCurrentInstance()

  const [revokeToken, { loading: isRevokingToken }] = useLazyFetch(`/api/services/tokens/${token.id}`, {
    method: 'DELETE',
  })

  const [updateTokenName, { loading: isUpdatingTokenName }] = useLazyFetch(`/api/services/tokens/${token.id}`, {
    method: 'PUT',
  })

  const handleRevokeToken = async () => {
    const { error } = await revokeToken()

    if (!error) {
      setRevokeMode(false)
      await mutate()
      if (!!replicas.find((i) => i.id === currentInstance.id) || withMetrics) {
        dispatch({ type: 'UPDATE_INSTANCE', value: { id: null } })
      }
    }
  }

  const handleSaveTokenName = async (newName) => {
    const { error } = await updateTokenName({
      body: JSON.stringify({
        name: newName,
      }),
    })

    if (!error) {
      await mutate()
    }
  }

  const count = useMemo(() => {
    if (data) {
      const lastPage = data[data.length - 1]
      return lastPage.count
    }

    return 0
  }, [data])

  const createdAt = useMemo(() => data?.[0]?.data?.[0]?.createdAt, [data])
  const updatedAt = useMemo(() => data?.[0]?.data?.[0]?.updatedAt, [data])

  return (
    <Box
      data-testid={`instance-row-${token?.id}`}
      key={token?.id}
      sx={{
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: isCardHover ? 'blue' : 'grays.3',
        position: 'relative',
      }}
    >
      <Card
        data-testid={`instance-row-card-${token?.id}`}
        onClick={() => setExpanded(!isExpanded)}
        onMouseEnter={() => setCardIsHover(true)}
        onMouseLeave={() => setCardIsHover(false)}
        p={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          border: 'none',
          ...(isExpanded ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {}),
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        variant="shadow"
      >
        {withMetrics ? (
          <Flex sx={{ alignSelf: 'stretch' }}>
            <Box
              sx={{
                flex: 4,
              }}
            >
              <InstanceCardHeader
                token={token}
                count={count}
                updatedAt={updatedAt}
                createdAt={createdAt}
                mutate={mutate}
                isHovered={isCardHover}
                onRevokeToken={() => setRevokeMode(true)}
                onRename={handleSaveTokenName}
                isWaiting={isUpdatingTokenName || isRevokingToken}
                withMetrics
              />
            </Box>
            <Flex
              sx={{
                flex: 6,
                bg: 'grays.0',
                borderLeftWidth: 1,
                borderLeftStyle: 'solid',
                borderLeftColor: 'grays.2',
                borderBottomRightRadius: 2,
                borderTopRightRadius: 2,
              }}
            >
              <InstanceCardMetrics token={token} />
            </Flex>
          </Flex>
        ) : (
          <InstanceCardHeader
            token={token}
            count={count}
            updatedAt={updatedAt}
            createdAt={createdAt}
            mutate={mutate}
            isHovered={isCardHover}
            onRevokeToken={() => setRevokeMode(true)}
            onRename={handleSaveTokenName}
            isWaiting={isUpdatingTokenName || isRevokingToken}
          />
        )}
      </Card>

      {revokeMode && (
        <ConfirmModal onConfirm={handleRevokeToken} onCancel={() => setRevokeMode(false)} isWaiting={isRevokingToken}>
          <Text>
            By deleting this instance, Traefik proxies will become <strong>inaccessible</strong> from Traefik Pilot, do
            you want to continue?
          </Text>
        </ConfirmModal>
      )}

      <ExpandableBox isExpanded={isExpanded}>
        {error && (
          <Box px={4} py={2}>
            <FormMessage
              message="Error fetching Traefik proxies. Please try again later or contact the support team."
              hasIcon
            />
          </Box>
        )}

        {hasSkeleton && (
          <Box py={1}>
            {[...Array(3)].map((_, i) => (
              <ReplicaRowSkeleton key={i} />
            ))}
          </Box>
        )}

        {!hasSkeleton && !error && (
          <>
            {!replicas?.length ? (
              <Flex py={2} px={4} sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Text pb={1} size={3} sx={{ fontWeight: 700 }}>
                  No Traefik proxies of this instance found.
                </Text>
                <Text pb={1}>
                  If you already registered proxies of this Traefik instance, take note that you need to restart it.
                </Text>
                <Text pb={1}>
                  If you are behind a firewall, you should allow some{' '}
                  <Link as="a" href="https://doc.traefik.io/traefik-pilot/ips/">
                    network flows
                  </Link>
                  .
                </Text>
                <Text
                  as="button"
                  onClick={() => setRevokeMode(true)}
                  sx={{ color: 'red', fontWeight: 700, '&:hover': { cursor: 'pointer', opacity: 0.5 } }}
                >
                  Delete this Instance
                </Text>
              </Flex>
            ) : (
              <>
                <Box py={1} data-testid={`instance-row-replicas-${token?.id}`}>
                  {replicas?.map((replica, i) => (
                    <ReplicaRow key={i} {...replica} />
                  ))}
                </Box>
                {!hitEnd && (
                  <Button variant="secondary" sx={{ width: '100%', bg: 'blues.2' }} onClick={() => setSize(size + 1)}>
                    Load more
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </ExpandableBox>
    </Box>
  )
}

export default InstanceCard
