import React from 'react'
import styled from 'styled-components'
import Icon from 'react-eva-icons'
import { Box, Text, theme } from '@traefiklabs/faency'

export const StatusWrapper = styled(Box)`
  height: 26px;
  width: 26px;
  padding: 0;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ color }) => `
    background-color: ${color};
  `}
`

export type Status = 'enabled' | 'disabled' | 'warning'

type Props = {
  status: Status
  label?: string
  withLabel?: boolean
}

type Value = { color: string; icon: string; label: string }

export const ResourceStatus: React.FC<Props> = ({ status, withLabel = false }) => {
  const valuesByStatus: { [key in Status]: Value } = {
    enabled: {
      color: '#00A697',
      icon: 'checkmark-circle-2',
      label: 'Success',
    },
    disabled: {
      color: theme.colors.red,
      icon: 'alert-triangle',
      label: 'Error',
    },
    warning: {
      color: '#db7d11',
      icon: 'alert-circle',
      label: 'Warning',
    },
  }

  const values = valuesByStatus[status]

  if (!values) {
    return null
  }

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <StatusWrapper color={values.color}>
        <Icon name={values.icon} size="medium" fill="white" />
      </StatusWrapper>
      {withLabel && values.label && (
        <Text size={1} ml={1} sx={{ color: values.color, fontWeight: 600 }}>
          {values.label}
        </Text>
      )}
    </Box>
  )
}
