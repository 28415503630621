import { Flex, Box, Button, Card } from '@traefiklabs/faency'
import ModalOverlay from './ModalOverlay'

type ConfirmModalProps = {
  children: React.ReactNode
  onCancel: () => void
  onConfirm: () => void
  isWaiting?: boolean
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ children, onCancel, onConfirm, isWaiting = false }) => {
  return (
    <>
      <ModalOverlay />
      <Card
        p="4"
        sx={{
          position: 'fixed',
          zIndex: 10,
          marginX: 'auto',
          left: 0,
          right: 0,
          top: 300,
          bottom: 0,
          maxWidth: 430,
          maxHeight: 170,
          boxShadow: 'rgba(14, 18, 22, 0.35) 0px 10px 38px -10px, rgba(14, 18, 22, 0.2) 0px 10px 20px -15px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        variant="shadow"
      >
        <Box mb={2}>{children}</Box>

        <Flex sx={{ justifyContent: 'space-between' }}>
          <Button
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              event.preventDefault()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="blue"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation()
              event.preventDefault()
              onConfirm()
            }}
            isWaiting={isWaiting}
            autoFocus
          >
            Continue
          </Button>
        </Flex>
      </Card>
    </>
  )
}

export default ConfirmModal
