import { Box, Card, Flex, Text } from '@traefiklabs/faency'
import { motion } from 'framer-motion'
import Icon from 'react-eva-icons'
import Link from '../Link'

const CustomCard = motion.custom(Card)

const WhyRegisterInstanceCard: React.FC = () => (
  <CustomCard
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: 'auto' }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.3 }}
    mb={3}
    sx={{ maxWidth: 470 }}
    variant="shadow"
  >
    <Flex pb={4} sx={{ justifyContent: 'center' }}>
      <Text size={3} sx={{ fontWeight: 700 }}>
        Why register my Traefik instances?
      </Text>
    </Flex>
    <Flex pb={3} sx={{ flexDirection: 'column' }}>
      <Flex pb={1} sx={{ alignItems: 'center' }}>
        <Text sx={{ color: '#44d7b6', display: 'inline-flex', alignItems: 'center' }}>
          <Icon name="checkmark-outline" size="large" fill="currentColor" />
        </Text>
        <Text size={3} pl={1}>
          {`Enable "Health Check" and "Security"`} <strong>Alerting</strong>
        </Text>
      </Flex>
      <Flex pb={1} sx={{ alignItems: 'center' }}>
        <Text sx={{ color: '#44d7b6', display: 'inline-flex', alignItems: 'center' }}>
          <Icon name="checkmark-outline" size="large" fill="currentColor" />
        </Text>
        <Text size={3} pl={1}>
          Get <strong>Metrics</strong> of your infrastructure
        </Text>
      </Flex>
      <Flex sx={{ alignItems: 'center' }}>
        <Text sx={{ color: '#44d7b6', display: 'inline-flex', alignItems: 'center' }}>
          <Icon name="checkmark-outline" size="large" fill="currentColor" />
        </Text>
        <Text size={3} pl={1}>
          Extend Traefik with <strong>Plugins</strong>
        </Text>
      </Flex>
    </Flex>
    <Box sx={{ textAlign: 'center' }}>
      <Link as="a" href="https://doc.traefik.io/traefik-pilot/plugins/overview/" target="_blank">
        Learn more
      </Link>
    </Box>
  </CustomCard>
)

export default WhyRegisterInstanceCard
