import React from 'react'
import Link from 'next/link'
import { Flex, Switch, Text } from '@traefiklabs/faency'
import useSWR from 'swr'
import { createGlobalStyle } from 'styled-components'
import { SUBSCRIPTION_SERVICE_PATH } from '../../hooks/ssr-use-subscriptions'

const GlobalStyle = createGlobalStyle`
  input[type="checkbox"] + div {
    z-index: inherit;
  }
`

const AlertSwitch: React.FC = () => {
  const { data: subscriptions } = useSWR<{ data?: any[] }>(SUBSCRIPTION_SERVICE_PATH)

  return (
    <>
      <GlobalStyle />
      <Link href="/alerting">
        <Flex
          sx={{
            cursor: 'pointer',
            alignItems: 'center',
            div: {
              cursor: 'pointer',
            },
          }}
        >
          <Text sx={{ paddingRight: 1, fontWeight: 'bold' }}>Alerts</Text>
          <Switch checked={!!subscriptions?.data?.length} readOnly />
        </Flex>
      </Link>
    </>
  )
}

export default AlertSwitch
