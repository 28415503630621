import useSWR from 'swr'
import semver from 'semver'

const useSecurities = (version) => {
  const { data: securityIssuesRes } = useSWR(`/api/services/securities`)

  return (securityIssuesRes?.data || []).reduce((acc, issue) => {
    const versionIsSatisfy = (issue?.versions || []).find((v) => {
      const versionToSatisfy = v.replace('v', '').replaceAll('|', ' - ')
      return semver.satisfies(version, versionToSatisfy)
    })
    if (versionIsSatisfy) {
      acc.push(issue)
    }
    return acc
  }, [])
}

export default useSecurities
