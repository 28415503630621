import useSWR from 'swr'
import { FormMessage } from '@traefiklabs/faency'
import { useCurrentInstance } from '../../contexts/current-instance'
import InstanceCard, { Skeleton } from './InstanceCard'

const fetchUrl = '/api/services/tokens/'

type CurrentInstanceProps = {
  onRevokeToken?: () => void
}

const CurrentInstance: React.FC<CurrentInstanceProps> = ({ onRevokeToken }) => {
  const { state: currentInstance } = useCurrentInstance()
  const { data: res, error, mutate } = useSWR(
    currentInstance?.id ? `${fetchUrl}?instanceId=${currentInstance?.id}` : null,
  )
  const token = res?.data

  if (error) {
    return (
      <FormMessage
        message="Error fetching current instance. Please try again later or contact the support team."
        hasIcon
      />
    )
  }

  if (!res) {
    return <Skeleton />
  }

  return <InstanceCard token={token} mutate={mutate} onRevokeToken={onRevokeToken} withMetrics />
}
export default CurrentInstance
