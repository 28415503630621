import React from 'react'
import { useRouter } from 'next/router'
import { DateTime } from 'luxon'
import { Button, Flex, SkeletonText, Text, Tooltip, theme } from '@traefiklabs/faency'
import styled from 'styled-components'
import Icon from 'react-eva-icons'
import { ResourceStatus, Status } from '../ResourceStatus'
import useSecurityIssues from '../../hooks/use-security-issues'

export const Skeleton = () => (
  <Flex px={3} py={1} sx={{ alignItems: 'center', flex: 1 }}>
    <Flex mr={2} sx={{ alignItems: 'center' }}>
      <SkeletonText sx={{ height: '26px !important', width: 26 }} />
    </Flex>
    <Flex mr={2} sx={{ alignItems: 'center' }}>
      <SkeletonText sx={{ width: 230 }} />
    </Flex>
    <Flex mr={2} sx={{ alignItems: 'center' }}>
      <SkeletonText sx={{ width: 110 }} />
    </Flex>
    <Flex mr={2} sx={{ alignItems: 'center', flex: 1 }}>
      <SkeletonText sx={{ width: 120 }} />
    </Flex>
    <Flex mr={2} sx={{ alignItems: 'center' }}>
      <SkeletonText sx={{ width: 18 }} />
    </Flex>
  </Flex>
)

export type ReplicaType = {
  id?: string
  name?: string
  version?: string
  codeName?: string
  createdAt?: string
  updatedAt?: string
  status?: Status
}

type Props = ReplicaType & {
  key?: string
  children?: JSX.Element
  sx?: any
}

const RoundButton = styled(Button)`
  border-radius: 20px;
  width: 40px !important;
  height: 40px !important;
  padding: 0;

  &:hover {
    background: ${theme.colors.grays[1]};
  }
`

export const ReplicaData: React.FC<ReplicaType> = ({ version, id, codeName, createdAt, updatedAt }) => {
  const securityIssues = useSecurityIssues(version)

  const diffFromNowVerbose = (date: string): string => {
    const now = DateTime.local()
    const luxonDt = DateTime.fromISO(date)
    return luxonDt.toRelative({ base: now, round: true, locale: 'en', style: 'narrow' })
  }

  const updatedAtDate = DateTime.fromISO(updatedAt)
  const diffFromNow = updatedAtDate.diffNow('minutes')?.values?.minutes

  const router = useRouter()

  return (
    <Flex sx={{ alignItems: 'center', flex: 1 }}>
      <Flex mr={2} sx={{ alignItems: 'center' }}>
        <Tooltip label={diffFromNow < -5 ? 'Last ping > 5min' : 'Up'} preferredAlignment="left">
          <ResourceStatus status={diffFromNow < -5 ? 'warning' : 'enabled'} />
        </Tooltip>
      </Flex>
      <Flex mr={2}>
        <Text size={2} sx={{ alignItems: 'center', fontWeight: 600 }}>{`Proxy#${id || 1}`}</Text>
      </Flex>
      {!!createdAt && (
        <Flex mr={2} sx={{ alignItems: 'center' }}>
          <Text size={1} sx={{ fontWeight: 500 }}>
            Created {diffFromNowVerbose(createdAt)}
          </Text>
        </Flex>
      )}
      <Flex mr={2} sx={{ alignItems: 'center', flex: 1 }}>
        <Text size={1} sx={{ fontWeight: 500 }}>
          {`${version} (${codeName})`}
        </Text>
        {!!securityIssues.length && (
          <Tooltip
            label={
              securityIssues.length > 1
                ? (((
                    <ul>
                      {securityIssues.map((si, idx) => (
                        <li key={idx}>{si.message}</li>
                      ))}
                    </ul>
                  ) as unknown) as string)
                : securityIssues[0].message
            }
            preferredAlignment="center"
          >
            <Text ml={1} sx={{ display: 'inline-flex', fontSize: 14, alignItems: 'center', fontWeight: 'bold' }}>
              <Icon name="alert-triangle" fill="#ffa100" />{' '}
              {`${securityIssues.length > 1 ? `+${securityIssues.length}` : ''}`}
            </Text>
          </Tooltip>
        )}
      </Flex>
      <RoundButton onClick={() => router.push(`/metrics?proxyId=${id}`)}>
        <Text sx={{ color: 'grays.5' }}>
          <Icon name="activity" fill="currentColor" />
        </Text>
      </RoundButton>
    </Flex>
  )
}

const ReplicaRow: React.FC<Props> = ({ key, children, ...replicaData }) => {
  return (
    <Flex px={3} py={1} {...(key ? { key } : {})}>
      {children || <ReplicaData {...replicaData} />}
    </Flex>
  )
}

export default ReplicaRow
