import React from 'react'
import { Box } from '@traefiklabs/faency'

import InstanceCard, { TokenType, Skeleton as InstanceCardSkeleton } from './InstanceCard'

export type InstanceListProps = {
  instances?: TokenType[]
  mutate: () => void
}

export const Skeleton: React.FC = () => (
  <Box mb={4} sx={{ flex: 1 }}>
    <InstanceCardSkeleton />
  </Box>
)

const InstanceList: React.FC<InstanceListProps> = ({ instances = [], mutate }) => {
  return (
    <>
      {instances.map((token: TokenType) => (
        <Box key={`${token.id}-${token.name}`} data-testid="instances-list" mb={4} sx={{ flex: 1 }}>
          <InstanceCard token={token} mutate={mutate} />
        </Box>
      ))}
    </>
  )
}

export default InstanceList
