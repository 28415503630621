import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Box, Button, Card, Flex, Heading, Chip, Text, BoxProps, SkeletonBox } from '@traefiklabs/faency'
import qs from 'query-string'
import Icon from 'react-eva-icons'

import generateDefaultTokenName from '../../utils/generate-default-token-name'
import useLazyFetch from '../../hooks/use-lazy-fetch'
import useAuth from '../../hooks/use-auth'
import PopoverMenu from '../PopoverMenu'
import ExpandableBox from '../ExpandableBox'
import ReplicaRow, { ReplicaType, Skeleton as ReplicaRowSkeleton } from '../replicas/ReplicaRow'
import CodeSnippet, { CodeSnippetType } from '../CodeSnippet'
import CopyableToken from '../CopyableToken'
import { TokenType } from './InstanceCard'
import EditableHeading from '../EditableHeading'
import { Formik } from 'formik'
import { InstanceNameSchema } from 'utils/form'
import { ToastContext } from 'contexts/toasts'

type InstanceToRegisterProps = {
  step?: 1 | 2
  currentReplica?: ReplicaType
  onNewRegistration: (newToken: TokenType) => void
  onStepChange?: (step: number) => void
}

type ModalOverlayProps = BoxProps & {
  bgAs?: 'blur' | 'gradient'
}

const ModalOverlay = styled<React.FC<ModalOverlayProps>>(Box)`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${(props) =>
    props.bgAs === 'gradient' &&
    `
    background: linear-gradient(to bottom, transparent, white);
  `}

  ${(props) =>
    props.bgAs === 'blur' &&
    `
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.6);

  @supports not ((backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px))) {
    background-color: rgba(255, 255, 255, 0.6);
  }
  `}
`

const StaticSkeletonWrapper = styled.div`
  * {
    animation: none;
  }
`

const InstanceToRegister: React.FC<InstanceToRegisterProps> = ({
  step: stepParam = 1,
  onNewRegistration,
  onStepChange,
  currentReplica,
}) => {
  const router = useRouter()
  const { user } = useAuth()
  const [step, setStep] = useState(stepParam)
  const [token, setToken] = useState<string | null>(router?.query?.token as string)
  const [tokenId, setTokenId] = useState<string | null>(router?.query?.tokenId as string)
  const [, setIsWaiting] = useState(false)
  const { addToast } = useContext(ToastContext)

  const [generateInstanceToken, { data, loading: generatingToken }] = useLazyFetch('/api/services/tokens/', {
    method: 'POST',
  })

  const [updateToken] = useLazyFetch(`/api/services/tokens/${tokenId}`, {
    method: 'PUT',
  })

  const [revokeToken, { loading: revokingToken }] = useLazyFetch(`/api/services/tokens/${tokenId}`, {
    method: 'DELETE',
  })

  useEffect(() => {
    if (onStepChange) {
      onStepChange(step)
    }
  }, [onStepChange, step])

  useEffect(() => {
    setStep(stepParam)
  }, [stepParam])

  useEffect(() => {
    if (step === 2 && !token && !tokenId) {
      generateInstanceToken({
        body: JSON.stringify({ name: generateDefaultTokenName(), state: 'pending' }),
      })
    }
  }, [step, token])

  useEffect(() => {
    if (token && tokenId) {
      const url = {
        pathname: '/instances',
        query: { action: 'register', token, tokenId },
      }
      router.replace(url, url, { shallow: true })
    }
  }, [token, tokenId])

  useEffect(() => {
    if (data) {
      setToken(data.value)
      setTokenId(data.id)
    }
  }, [data])

  const resetState = () => {
    setToken(null)
    setTokenId(null)
    setStep(1)
    router.replace('/instances', '/instances', { shallow: true })
  }

  const done = async (values: { newName: string }) => {
    setIsWaiting(true)

    const { data, error } = await updateToken({
      body: JSON.stringify({
        name: values.newName?.trim(),
        state: 'active',
      }),
    })

    setIsWaiting(false)

    if (!error) {
      resetState()
      await onNewRegistration(data)
    } else {
      addToast({
        severity: 'error',
        isVisible: true,
        message: 'There was an error while trying to activate the token.',
      })
    }
  }

  const authRedirectUrl = useMemo(() => {
    const authRedirectUrlParam = router?.query?.authRedirectUrl as string

    if (authRedirectUrlParam) {
      const embedRedirectUrlStr = qs.stringifyUrl({
        url: authRedirectUrlParam,
        query: { platform: '/instances?action=register' },
        fragmentIdentifier: null,
      })

      // makes URL work server-side
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const URL = typeof window === 'undefined' ? require('url').URL : window.URL
      const url = new URL(embedRedirectUrlStr)

      // fix redirect url to work with traefik having hash in it
      return `${url.origin}/#/${url.search}`
    }

    return '/instances?action=register'
  }, [router?.query?.authRedirectUrl])

  const codeSnippetData: CodeSnippetType[] = [
    {
      name: 'File (YAML)',
      lang: 'yaml',
      text: `pilot:
    token: "${token}"`,
    },
    {
      name: 'File (TOML)',
      lang: 'toml',
      text: `[pilot]
    token = "${token}"`,
    },

    {
      name: 'CLI',
      lang: 'bash',
      text: `--pilot.token=${token}`,
    },
  ]

  return (
    <Card
      p={0}
      sx={{
        position: 'relative',
        minHeight: 214,
        display: 'flex',
        alignSelf: 'stretch',
        borderWidth: 2,
        borderStyle: 'dashed',
      }}
      variant="shadow"
    >
      <Formik
        initialValues={{ newName: generateDefaultTokenName() }}
        validationSchema={InstanceNameSchema}
        onSubmit={done}
      >
        {(formikProps) => (
          <form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
            <Flex p={4} sx={{ maxHeight: 96, flex: 1, alignSelf: 'stretch', alignItems: 'center' }}>
              {step === 1 && (
                <>
                  <Heading sx={{ flex: 1 }}>{currentReplica ? 'Current Traefik Instance' : 'New Instance'}</Heading>
                  <Chip mr={4} variant="blue">
                    1 replica
                  </Chip>
                  <PopoverMenu disabled />
                </>
              )}

              {step === 2 && (
                <>
                  <Box mr={4} sx={{ flex: 1 }}>
                    <EditableHeading
                      onValueChange={(value) => {
                        formikProps?.setFieldValue('newName', value)
                      }}
                      validationErrors={formikProps.errors}
                      onlyEditMode
                      noButton
                    >
                      {formikProps?.values.newName}
                    </EditableHeading>
                  </Box>
                  {!!token && (
                    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                      {generatingToken ? (
                        <SkeletonBox sx={{ width: 371, height: 24 }} />
                      ) : (
                        <CopyableToken value={token} />
                      )}
                      <Flex mt={1} sx={{ color: 'orange', fontStyle: 'italic', justifyContent: 'baseline' }}>
                        <Icon name="alert-triangle-outline" fill="currentColor" size="medium" />
                        This token is only visible during the connection process.
                      </Flex>
                    </Flex>
                  )}
                </>
              )}
            </Flex>

            {step === 1 && (
              <>
                <ModalOverlay bgAs={currentReplica ? 'gradient' : 'blur'} />
                <Flex
                  p={2}
                  sx={{
                    position: 'absolute',
                    zIndex: 1,
                    top: currentReplica ? 96 : 0,
                    bottom: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    {...(user
                      ? { onClick: () => setStep(2) }
                      : {
                          as: 'a',
                          href: `/api/signup?${qs.stringify({ 'redirect-url': authRedirectUrl })}`,
                        })}
                    variant="primary"
                  >{`Register ${currentReplica ? 'Current' : 'New'} Traefik Instance`}</Button>
                </Flex>
              </>
            )}

            <ExpandableBox isExpanded={true} dashedBorder>
              <Box py={1} sx={{ position: 'relative' }}>
                {!!currentReplica && <ReplicaRow {...currentReplica} />}
                {step === 1 &&
                  [...Array(3)].map((_, i) => (
                    <StaticSkeletonWrapper key={i}>
                      <ReplicaRowSkeleton />
                    </StaticSkeletonWrapper>
                  ))}

                {step === 2 && (
                  <>
                    {[...Array(9)].map((_, i) => (
                      <StaticSkeletonWrapper key={i}>
                        <ReplicaRowSkeleton />
                      </StaticSkeletonWrapper>
                    ))}
                    <ModalOverlay bgAs="blur" />
                    <Flex
                      p={2}
                      sx={{
                        position: 'absolute',
                        zIndex: 1,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text pb={3} sx={{ fontWeight: 'bold' }}>
                        Connect your Traefik proxies adding this token in your static configuration:
                      </Text>
                      {generatingToken ? (
                        <Box sx={{ width: 500 }}>
                          <SkeletonBox mb={1} mr={1} px={1} sx={{ width: 216, height: 36 }} />
                          <SkeletonBox p={4} sx={{ width: 500, height: 94 }} />
                        </Box>
                      ) : (
                        <CodeSnippet data={codeSnippetData} snippetMinHeight={118} />
                      )}

                      <Text py={4} sx={{ fontWeight: 'bold' }}>
                        Your proxies will be connected after a restart of Traefik.
                      </Text>

                      <Flex>
                        <Button
                          mx={3}
                          onClick={async () => {
                            await revokeToken()
                            resetState()
                          }}
                          isWaiting={revokingToken}
                        >
                          Cancel
                        </Button>
                        <Button
                          mx={3}
                          variant={!formikProps.errors.newName ? 'primary' : 'gray'}
                          disabled={!!formikProps.errors.newName}
                          onClick={(e) => {
                            // Prevent Default so we don't trigger a page refresh
                            e.preventDefault()
                            if (!formikProps.errors.newName) {
                              formikProps.submitForm().then(() => formikProps.resetForm())
                            }
                          }}
                        >
                          <Text pr={1} sx={{ color: 'inherit' }}>
                            I have restarted my Traefik instance
                          </Text>
                          <Icon name="checkmark-outline" fill="currentColor" size="large" />
                        </Button>
                      </Flex>
                    </Flex>
                  </>
                )}
              </Box>
            </ExpandableBox>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default InstanceToRegister
