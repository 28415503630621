import { isoFetch } from '../utils/fetcher'
import { SubscriptionWithId } from './use-subscription'
import auth0 from '../utils/auth0'

export const SUBSCRIPTION_SERVICE_PATH = `${
  process.env.SUBSCRIPTION_SERVICE_ENDPOINT || '/api/services/subscriptions'
}/`

export const useServerSideSubscriptions = async (ctx) => {
  const tokenCache = auth0.tokenCache(ctx.req, ctx.res)
  const { accessToken } = await tokenCache.getAccessToken()

  const subscriptions = await isoFetch(SUBSCRIPTION_SERVICE_PATH, {}, accessToken).then((res) => res.data)

  if (!subscriptions) {
    return makeCurrentSubscriptions([])
  }

  // check if subscriptions are alright, otherwise re-create them
  const idList = []
  let email = ''
  let webHook = ''
  let hasEmailProblem = false
  let hasWebHookProblem = false
  const countByType = { HeartBeat: 0, SecurityIssue: 0 }

  const subsByType = subscriptions.reduce((acc, cur) => {
    acc[cur.alertType] = cur
    idList.push(cur.id)

    // To check if there are more than 1 subscription per alert type
    if (!countByType[cur.alertType]) {
      countByType[cur.alertType] = 0
    }
    countByType[cur.alertType]++

    // Check if there are more than 1 email registered
    if (email !== '' && cur.email !== email) {
      hasEmailProblem = true
    }

    // Check if there are more than 1 webHook registered
    if (webHook !== '' && cur.webHook !== webHook) {
      hasWebHookProblem = true
    }

    // Save the current email and webHook for sub re-creation if needed
    email = cur.email
    webHook = cur.webHook

    return acc
  }, {})

  if (
    hasEmailProblem ||
    hasWebHookProblem ||
    Object.keys(countByType).length > 2 ||
    countByType.HeartBeat > 1 ||
    countByType.SecurityIssue > 1
  ) {
    await deleteSubscriptions(idList, accessToken)
    const newSubscriptions = await createSubscriptions(Object.keys(subsByType), email, webHook, accessToken)

    return makeCurrentSubscriptions(newSubscriptions)
  }

  // build an object that represent the current subscriptions
  return makeCurrentSubscriptions(subscriptions)
}

export const deleteAllSubscriptions = async (accessToken?: string) => {
  const subscriptions: SubscriptionWithId[] = await isoFetch(SUBSCRIPTION_SERVICE_PATH, {}, accessToken).then(
    (res) => res.data,
  )
  return deleteSubscriptions(subscriptions.map((s) => s.id))
}

export const deleteSubscription = async (id: string, accessToken?: string) => {
  return isoFetch(`${SUBSCRIPTION_SERVICE_PATH}${id}`, { method: 'DELETE' }, accessToken)
}

const deleteSubscriptions = async (ids: string[], accessToken?: string) => {
  return Promise.all(ids.map((id) => deleteSubscription(id, accessToken)))
}

export const createSubscription = async (alertType: string, email?: string, webHook?: string, accessToken?: string) => {
  return isoFetch(
    SUBSCRIPTION_SERVICE_PATH,
    {
      method: 'POST',
      body: JSON.stringify({
        alertType,
        email,
        webHook,
      }),
    },
    accessToken,
  ).then((res) => res.data)
}

const createSubscriptions = async (
  alertTypes: string[],
  email: string,
  webHook: string,
  accessToken?: string,
): Promise<SubscriptionWithId[]> => {
  return Promise.all(alertTypes.map((alertType) => createSubscription(alertType, email, webHook, accessToken)))
}

export const updateSubscription = async (subscription: SubscriptionWithId, accessToken?: string) => {
  return isoFetch(
    `${SUBSCRIPTION_SERVICE_PATH}${subscription.id}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        alertType: subscription.alertType,
        email: subscription.email,
        webHook: subscription.webHook,
      }),
    },
    accessToken,
  )
}

export type CurrentSubscriptions = {
  HeartBeat?: SubscriptionWithId
  SecurityIssue?: SubscriptionWithId
}
const makeCurrentSubscriptions = (subscriptions: SubscriptionWithId[]): { subscriptions: CurrentSubscriptions } => {
  const heartBeat = subscriptions.find((s) => s.alertType === 'HeartBeat')
  const securityIssue = subscriptions.find((s) => s.alertType === 'SecurityIssue')
  const current = {} as CurrentSubscriptions

  if (heartBeat) {
    current.HeartBeat = heartBeat
  }

  if (securityIssue) {
    current.SecurityIssue = securityIssue
  }

  return { subscriptions: current }
}
