import { useState, useRef, useEffect, useMemo } from 'react'
import { Flex, Heading, Input, Button, Box } from '@traefiklabs/faency'
import Icon from 'react-eva-icons'
import { Formik, FormikErrors, FormikProps } from 'formik'
import { InstanceNameSchema } from 'utils/form'

type EditableHeadingProps = {
  children: string
  onValueChange?: (newName: string) => void
  isHover?: boolean
  isLoading?: boolean
  onlyEditMode?: boolean
  noButton?: boolean
  validationErrors?: FormikErrors<{ newName: string }>
}

const EditableHeading: React.FC<EditableHeadingProps> = ({
  children = '',
  onlyEditMode = false,
  noButton = false,
  isLoading = false,
  onValueChange,
  isHover: isHoverProp,
  validationErrors,
}) => {
  const [isHoverTitle, setIsHoverTitle] = useState(isHoverProp || false)
  const [isHoverButton, setIsHoverButton] = useState(false)
  const [isEditMode, setEditMode] = useState(onlyEditMode)
  const [isWaiting, setIsWaiting] = useState(false)
  const clickOutsideRef = useRef(null)

  const isHover = useMemo(() => isHoverTitle || isHoverButton, [isHoverTitle, isHoverButton])

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation() // not working due to this issue https://github.com/facebook/react/issues/4335 but will work in React 17
      if (!onlyEditMode && clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
        setEditMode(false)
      }
    }

    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [clickOutsideRef])

  const handleSave = async (values: { newName: string }) => {
    setIsWaiting(true)
    try {
      await onValueChange(values.newName)
      if (!onlyEditMode) {
        setEditMode(false)
      }
    } catch (error) {
      console.error(error)
    }
    if (!onlyEditMode) {
      setIsWaiting(false)
    }
  }

  return (
    <>
      {isEditMode ? (
        noButton ? (
          <EditableField
            clickOutsideRef={clickOutsideRef}
            value={children}
            noButton={noButton}
            onValueChange={onValueChange}
            validationErrors={validationErrors}
            isLoading={isLoading}
          />
        ) : (
          <Formik initialValues={{ newName: children }} validationSchema={InstanceNameSchema} onSubmit={handleSave}>
            {(formikProps) => (
              <form>
                <EditableField
                  clickOutsideRef={clickOutsideRef}
                  formikProps={formikProps}
                  noButton={noButton}
                  validationErrors={formikProps?.errors}
                  isLoading={isLoading}
                />
              </form>
            )}
          </Formik>
        )
      ) : (
        <Flex onMouseEnter={() => setIsHoverTitle(true)} onMouseLeave={() => setIsHoverTitle(false)}>
          <Heading
            mr={1}
            sx={{
              position: 'relative',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {children}
            {isHover && (
              <Button
                variant="secondary"
                px={1}
                ml={1}
                sx={{
                  zIndex: 1,
                  position: 'absolute',
                  right: -46,
                  display: 'inline-flex',
                  height: 28,
                  alignItems: 'center',
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setEditMode(true)
                }}
                isWaiting={isWaiting}
                onMouseEnter={() => setIsHoverButton(true)}
                onMouseLeave={() => setIsHoverButton(false)}
              >
                <Icon name="edit-2-outline" fill="currentColor" />
              </Button>
            )}
          </Heading>
        </Flex>
      )}
    </>
  )
}

export default EditableHeading

type EditableFieldProps = {
  clickOutsideRef: any
  value?: string
  formikProps?: FormikProps<{ newName: string }>
  onValueChange?: (newName: string) => void
  isLoading: boolean
  validationErrors?: FormikErrors<{ newName: string }>
  noButton?: boolean
}

const EditableField: React.FC<EditableFieldProps> = ({
  clickOutsideRef,
  formikProps,
  value,
  onValueChange,
  isLoading,
  noButton,
  validationErrors,
}) => {
  return (
    <Flex sx={{ alignItems: 'center', flexDirection: 'column' }} ref={clickOutsideRef}>
      <Flex sx={{ flexDirection: 'row', width: '100%' }}>
        <Input
          data-testid="heading-input"
          autoFocus
          mr={1}
          size={0}
          sx={{ fontSize: 2, height: 32 }}
          value={formikProps?.values.newName || value}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            if (noButton) {
              onValueChange(e.target.value)
            }

            if (formikProps) {
              formikProps?.setFieldValue('newName', e.target.value)
            }
          }}
          placeholder="Instance Name"
          variant="shadow"
        />
        {!noButton && (
          <Button
            isWaiting={isLoading}
            variant="secondary"
            sx={{
              display: 'inline-flex',
              height: 32,
              alignItems: 'center',
              color: 'blue',
              '&:focus': { color: isLoading ? 'gray' : 'blue', opacity: 0.5 },
            }}
            disabled={!!formikProps?.errors.newName}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (!formikProps?.errors.newName) {
                formikProps?.submitForm()
              }
            }}
          >
            Save
          </Button>
        )}
      </Flex>
      {validationErrors.newName && (
        <Box role="alert" sx={{ pt: 1, fontSize: 1, color: 'red', width: '100%' }}>
          {validationErrors.newName}
        </Box>
      )}
    </Flex>
  )
}
