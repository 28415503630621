import React from 'react'
import useSWR from 'swr'
import qs from 'query-string'
import { Box, Flex, Grid, Text, SkeletonText, FormMessage } from '@traefiklabs/faency'
import { TokenType } from './InstanceCard'
import nFormatter from '../../utils/n-formatter'

type InstanceCardMetricsProps = {
  token: TokenType
}

const InstanceCardMetrics: React.FC<InstanceCardMetricsProps> = ({ token }) => {
  const { data: res, error } = useSWR(() => {
    const params = {
      relative: '-24h',
      instanceId: token.id,
    }

    return `/api/services/metrics/summary?${qs.stringify(params)}`
  })

  const isLoading = !res && !error
  const metrics = res?.data || []

  return (
    <Flex sx={{ justifyContent: 'space-around', alignItems: 'center', flex: 1 }} px={2}>
      {!!error && (
        <Box px={3}>
          <FormMessage
            message="Error fetching current instance metrics. Please try again later or contact the support team."
            hasIcon
          />
        </Box>
      )}
      {isLoading &&
        [...Array(3)].map((_, i) => (
          <Flex key={i} sx={{ flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
            <SkeletonText sx={{ height: '24px !important', width: '120px !important', textAlign: 'center' }} />
            <SkeletonText sx={{ height: '32px !important', width: '60px !important', textAlign: 'center' }} />
            <SkeletonText sx={{ height: '19px !important', width: '40px !important', textAlign: 'center' }} />
          </Flex>
        ))}
      {!error && !isLoading && (
        <>
          {metrics.length > 0 ? (
            <Flex
              py={2}
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                flex: 1,
                alignSelf: 'stretch',
              }}
            >
              <Grid
                pb={1}
                px={2}
                style={{
                  gridTemplateColumns: `repeat(auto-fill,minmax(160px,1fr))`,
                  gridGap: 20, // not accepted using sx ?!
                  alignSelf: 'stretch',
                }}
              >
                {metrics.map((metric) => (
                  <Flex key={metric.name} sx={{ flexDirection: 'column', justifyContent: 'center' }}>
                    <Text size={3} mb={1} sx={{ textAlign: 'center', color: 'grays.5', fontWeight: 600 }}>
                      {metric.visibleName}
                    </Text>
                    <Box sx={{ textAlign: 'center' }}>
                      <Text
                        size={7}
                        mr="2px"
                        sx={{ fontWeight: 700, fontFamily: 'monospace', letterSpacing: '-0.1em' }}
                      >
                        {Number.isInteger(metric.value) ? nFormatter(metric.value, 0) : nFormatter(metric.value, 1)}
                      </Text>
                      {typeof metric.unit !== 'undefined' && metric.unit.length < 4 && (
                        <Text pl="5px" size={1} sx={{ fontWeight: 'bold' }}>
                          {metric.unit}
                        </Text>
                      )}
                    </Box>
                  </Flex>
                ))}
              </Grid>
              {metrics.length > 0 && (
                <Box>
                  <Text size={0} sx={{ color: 'grays.5' }}>
                    last 24h metrics
                  </Text>
                </Box>
              )}
            </Flex>
          ) : (
            <Text sx={{ fontWeight: 'bold' }}>There is no metrics data yet</Text>
          )}
        </>
      )}
    </Flex>
  )
}

export default InstanceCardMetrics
